import React from 'react';
import Footer from '../components/Footer';
import HeaderDark from '../components/HeaderDark';
import  "../styles/321global.css"
import  "../styles/launchpad.css"
import  "../styles/circles.css"
import '../styles/nav.css'
import { Helmet } from "react-helmet"
import ProcessGraphic from '../components/ProcessGraphic'
import LPCarrierLogos from '../img/lp-carrier-logos.png'
import Nav from '../components/Nav'
import GetForm from '../components/GetForm';



const LaunchPad = () => {
    return (
        <div id="launchpad_bg">
            <Nav></Nav>
            <div id="launchpad">
                <Helmet><title>Insurance321 Quote: Free Business Insurance Quote</title><meta name="description" content="Complete the quote request and an agent will contact you"></meta></Helmet>
                <HeaderDark />
                <div id="quote_page_heading"></div>
                {/* <QuoteForm /> */}
                <GetForm />
                <div id="launchpad_circles">
                    <div className="circle-container">
                        <div id="carrier_logos"><img src={LPCarrierLogos} alt="Launchpad Carrier Logos" /></div>
                    <div className="circle" id="first_circle">
                        <div className="circle">
                            <div className="circle">
                                <div className="circle">
                                    <div className="circle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ProcessGraphic />
                <Footer />
            </div>
        </div>
        
     );
}
 
export default LaunchPad;